<template>
  <div class="notice_main">
    <WgPhoneTitle
      :columnIds="columnIds"
      :title="title"
      msg="掌握二外实时资讯，了解最新动态"
    />
    <div class="content">
      <div class="item" v-for="(item, index) in datas" :key="index">
        <div class="time">
          <span class="day">{{ $moment(item.publishTime).format("DD") }}</span>
          <span class="date">{{
            $moment(item.publishTime).format("YYYY.MM")
          }}</span>
        </div>
        <div class="title">
          <div class="ellipsis3">{{ item.title }}</div>
        </div>
        <div class="look">查看详情</div>
      </div>
    </div>
  </div>
</template>
<script>
import WgPhoneTitle from "./WgPhoneTitle.vue";
export default {
  name: "WgPhoneNotice",
  components: { WgPhoneTitle },
  props: {
    columnIds: {
      type: String,
      default: () => {
        return "";
      },
    },
    title: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {
      datas: [],
    };
  },
  methods: {
   // toDetail(item) {},
  },
  watch: {
    columnIds: {
      async handler(val) {
        this.activeIndex = 0;
        if (val) {
          const params = {
            pageNumber: 0,
            pageSize: 10,
            columnIds: val,
          };
          const res = await this.API.basic.getHomeRotographList(params);

          this.datas = res.data.content.slice(0, 4);
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.ellipsis2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.ellipsis3 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.notice_main {
  width: 100%;
  height: 1200px;
  background: url("../../assets/img/phone/bg2.png");
  background-size: 100% 100%;
  padding: 100px 37px;
  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 64px;
    .item {
      width: 322px;
      height: 420px;
      background: rgba($color: #fff, $alpha: 0.8);
      box-shadow: 0px 4px 68px 0px rgba(145, 155, 171, 0.13);
      border-radius: 8px;
      margin-bottom: 20px;
      padding: 32px 27px;
      .time {
        width: 93px;
        height: 93px;
        border-radius: 8px;
        border: 2px solid #f07a76;
        margin-bottom: 36px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span {
         // font-family: Gotham;
          font-weight: 400;
          color: #f07a76;
        }
        .date {
          font-size: 18px;
        }
        .day {
          font-size: 30px;
        }
      }
      .title {
       // font-family: PingFang SC;
        font-weight: 500;
        font-size: 30px;
        color: #000000;
        margin-bottom: 55px;
        height: 140px;
      }
      .look {
      //  font-family: PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #b3b3b3;
      }
    }
  }
}
</style>
