<template>
  <div class="study_main">
    <WgPhoneTitle
      :columnIds="columnIds"
      :title="title"
      theme="light"
      msg="掌握二外实时资讯，了解最新动态"
    />
    <div class="bg"></div>
    <van-swipe autoplay="5000" touchable class="swipe" indicator-color="#fff">
      <van-swipe-item v-for="item in datas" :key="item.id">
        <div class="content">
          <div class="title">
            <img src="../../assets/img/phone/yinhao2.png" />
            <div class="ellipsis1">{{ item.title }}</div>
            <img src="../../assets/img/phone/yinhao1.png" />
          </div>
          <div class="line"></div>
          <div class="des">
            <div class="ellipsis2">{{ item.description }}</div>
          </div>
          <div class="look">去看看</div>
        </div>
      </van-swipe-item></van-swipe
    >
  </div>
</template>
<script>
import WgPhoneTitle from "./WgPhoneTitle.vue";
export default {
  name: "WgPhoneNews",
  components: { WgPhoneTitle },
  props: {
    columnIds: {
      type: String,
      default: () => {
        return "";
      },
    },
    title: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {
      datas: [],
    };
  },
  methods: {
    toDetail(item) {},
  },
  watch: {
    columnIds: {
      async handler(val) {
        this.activeIndex = 0;
        if (val) {
          const params = {
            pageNumber: 0,
            pageSize: 10,
            columnIds: val,
          };
          const res = await this.API.basic.getHomeRotographList(params);

          this.datas = res.data.content;
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.ellipsis1 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.ellipsis2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.ellipsis3 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.study_main {
  width: 100%;
  height: 1000px;
  background: url("../../assets/img/phone/bg5.png");
  background-size: 100% 100%;
  padding: 100px 37px;
  position: relative;
  .bg {
    width: 706px;
    height: 620px;
    background: url("../../assets/img/phone/bg3.png");
    background-size: 100% 100%;
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
  }
  .swipe {
    position: absolute;
    width: 706px;
    height: 650px;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    ::v-deep .van-swipe__indicators {
      bottom: 0px;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      //justify-content: center;
      padding-top: 25%;
      height: 100%;
      .title {
       // font-family: PingFang;
        font-weight: bold;
        font-size: 48px;
        color: #000000;
        width: 500px;
        display: flex;
        align-items: center;
        gap: 20px;
        img {
          width: 45px;
          height: 39px;
        }
      }
      .line {
        width: 65px;
        height: 4px;
        background: #b32924;
        margin-top: 40px;
      }
      .des {
        width: 500px;
       // font-family: PingFang SC;
        font-weight: 400;
        font-size: 30px;
        color: #000000;
        margin-top: 40px;
      }
      .look {
        width: 159px;
        height: 64px;
        background: #c03530;
        border-radius: 32px;
        //font-family: PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #ffffff;
        line-height: 29px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 100px;
      }
    }
  }
}
</style>
