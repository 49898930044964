<template>
  <div class="main">
    <WgPhoneCarousel
      :columnIds="$columnIdFile['轮播图']"
      @toDetail="toDetail"
    />
    <WgPhoneNews
      :columnIds="$columnIdFile['综合新闻']"
      title="要闻速递"
      @toDetail="toDetail"
    />
    <WgPhoneNotice
      :columnIds="$columnIdFile['综合新闻']"
      title="通知公告"
      @toDetail="toDetail"
    />
    <WgPhoneStudy
      :columnIds="$columnIdFile['综合新闻']"
      title="学术动态"
      @toDetail="toDetail"
    />
    <WgPhoneList
      :columnIds="[$columnIdFile['综合新闻'], $columnIdFile['综合新闻']]"
      :title="['媒体二外', '校园看点']"
      @toDetail="toDetail"
    />
    <WgPhoneEnrollment title="乘风破浪 二外起航" @toDetail="toDetail" :columnIds="$columnIdFile['综合新闻']"/>
  </div>
</template>
<script>
import WgPhoneCarousel from "../components/Phone/WgPhoneCarousel.vue";
import WgPhoneNews from "../components/Phone/WgPhoneNews.vue";
import WgPhoneNotice from "../components/Phone/WgPhoneNotice.vue";
import WgPhoneStudy from "../components/Phone/WgPhoneStudy.vue";
import WgPhoneList from "../components/Phone/WgPhoneList.vue";
import WgPhoneEnrollment from "../components/Phone/WgPhoneEnrollment.vue";
export default {
  components: {
    WgPhoneEnrollment,
    WgPhoneCarousel,
    WgPhoneNews,
    WgPhoneNotice,
    WgPhoneStudy,
    WgPhoneList,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.main {
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
