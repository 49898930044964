<template>
  <div class="title_main">
    <div class="title_row">
      <div class="title" :style="theme === 'light' ? 'color: #fff;' : ''">
        {{ title }}
      </div>
      <div class="bt" :style="theme === 'light' ? 'color: #fff;' : ''">
        <span >更多</span>
      </div>
    </div>
    <div class="msg" :style="theme === 'light' ? 'color: rgba(255,255,255,0.5);' : ''">{{ msg }}</div>
  </div>
</template>
<script>
export default {
  name: "WgPhoneTitle",
  props: {
    columnIds: {
      type: String,
      default: () => {
        return "";
      },
    },
    title: {
      type: String,
      default: () => {
        return "";
      },
    },
    msg: {
      type: String,
      default: () => {
        return "";
      },
    },
    theme: {
      type: String,
      default: () => {
        return "dark";
      },
    },
  },
  methods: {
    toDetail(item) {},
  },
};
</script>
<style lang="scss" scoped>
.title_main {
  width: 100%;
  .msg {
    //font-family: PingFang;
    font-weight: 500;
    font-size: 26px;
    color: #a7a7a7;
    line-height: 28px;
  }
  .title_row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 38px;
    .title {
      //font-family: PingFang;
      font-weight: bold;
      font-size: 55px;
      color: #000000;
      line-height: 28px;
    }
    .bt {
      width: 91px;
      height: 45px;
      border-radius: 23px;
      border: 2px solid #a8a8a8;
      opacity: 0.35;
      display: flex;
      align-items: center;
      justify-content: center;
      // span {
        //font-family: PingFang;
        font-weight: 400;
        font-size: 24px;
        color: #000000;
        line-height: 28px;
        opacity: 0.6;
      //}
    }
  }
}
</style>
