<template>
  <div class="list_main">
    <WgPhoneTitle
      :columnIds="columnIds[0]"
      :title="title[0]"
      msg="掌握二外实时资讯，了解最新动态"
    />
    <div class="first" v-for="(item, index) in datas" :key="index">
      <div class="title">
        <div class="ellipsis1">{{ item.title }}</div>
      </div>
      <div class="des">
        <div class="ellipsis2">{{ item.description }}</div>
      </div>
    </div>
    <WgPhoneTitle
      :columnIds="columnIds[1]"
      :title="title[1]"
      msg="掌握二外实时资讯，了解最新动态"
    />
    <div class="carousel_main">
      <van-swipe
        style="height: 100%"
        autoplay="5000"
        touchable
        indicator-color="#C03530"
        ><van-swipe-item v-for="item in datas2" :key="item.id">
          <div class="content">
            <img :src="item.thumbnailInfo.url" style="object-fit: cover" />
            <div class="title">
              <div class="ellipsis2">{{ item.title }}</div>
            </div>
            <div class="des">
              <div class="ellipsis2">{{ item.description }}</div>
            </div>
          </div>
        </van-swipe-item></van-swipe
      >
      <div class="look">去看看</div>
    </div>
  </div>
</template>
<script>
import WgPhoneTitle from "./WgPhoneTitle.vue";
export default {
  name: "WgPhoneList",
  components: { WgPhoneTitle },
  props: {
    columnIds: {
      type: Array,
      default: () => {
        return [];
      },
    },
    title: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      datas: [],
      datas2: [],
    };
  },
  methods: {
    toDetail(item) {},
  },
  watch: {
    columnIds: {
      async handler(val) {
        console.log(val);
        this.activeIndex = 0;
        if (val.length > 0) {
          const params = {
            pageNumber: 0,
            pageSize: 10,
            columnIds: val[0],
          };
          const res = await this.API.basic.getHomeRotographList(params);
          this.datas = res.data.content.slice(0, 3);
          const params2 = {
            pageNumber: 0,
            pageSize: 10,
            columnIds: val[1],
          };
          const res2 = await this.API.basic.getHomeRotographList(params2);
          this.datas2 = res2.data.content;
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.ellipsis3 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.ellipsis2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.ellipsis1 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.list_main {
  width: 100%;
  height: 2000px;
  background: url("../../assets/img/phone/bg4.png");
  background-size: 100% 100%;
  padding: 100px 37px;
  .first {
    width: 675px;
    height: 205px;
    box-shadow: 0px 4px 68px 0px rgba(145, 155, 171, 0.13);
    border-radius: 8px;
    border: 2px solid #ffffff;
    margin: 26px 0px 52px 0;
    padding: 30px;
    .title {
      //font-family: PingFang SC;
      font-weight: 400;
      font-size: 26px;
      color: #333333;
    }
    .des {
      //font-family: PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #c03530;
      margin-top: 30px;
    }
  }
  .carousel_main {
    height: 800px;
    margin-top: 43px;
    position: relative;
    .look {
      width: 159px;
      height: 64px;
      background: #c03530;
      border-radius: 32px;
     // font-family: PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #ffffff;
      line-height: 29px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0px;
    }
    .content {
      margin-right: 30px;
      img {
        width: 669px;
        height: 387px;
        background: #000000;
        border-radius: 10px;
        margin-bottom: 56px;
      }
      .title {
        //font-family: PingFang SC;
        font-weight: 500;
        font-size: 36px;
        color: #000000;
        margin-bottom: 56px;
      }
      .des {
      //  font-family: PingFang SC;
        font-weight: 400;
        font-size: 26px;
        color: #000000;
        opacity: 0.6;
      }
    }
  }
  ::v-deep .van-swipe__indicator {
    background-color: #c03530;
  }
  ::v-deep .van-swipe__indicators {
    left: 90%;
    bottom: 30px;
  }
}
</style>
